import Swiper, { Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let latestNewsSwiperWr = document.querySelectorAll('.latest-news-swiper-wr');
latestNewsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation],
      observer: true,
      observeParents: true,
      slidesPerView: 'auto',
      autoHeight: false,
      initialSlide: 3,
      centeredSlides: true,
      spaceBetween: 0,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      allowTouchMove: true,
    });

    function updateSlideClasses() {
      const slides = document.querySelectorAll('.swiper-slide');
      slides.forEach((slide) => {
        slide.classList.remove(
          'swiper-slide-prev-prev',
          'swiper-slide-next-next',
          'swiper-slide-prev-prev-prev',
          'swiper-slide-next-next-next'
        );
      });

      const activeIndex = swiper.activeIndex;

      const indices = [
        activeIndex - 3,
        activeIndex - 2,
        activeIndex - 1,
        activeIndex + 1,
        activeIndex + 2,
        activeIndex + 3,
      ];

      indices.forEach((index, i) => {
        if (index >= 0 && index < swiper.slides.length) {
          const slide = swiper.slides[index];
          if (i === 0) slide.classList.add('swiper-slide-prev-prev-prev');
          else if (i === 1) slide.classList.add('swiper-slide-prev-prev');
          else if (i === 4) slide.classList.add('swiper-slide-next-next');
          else if (i === 5) slide.classList.add('swiper-slide-next-next-next');
        }
      });
    }

    swiper.on('slideChange', updateSlideClasses);

    updateSlideClasses();
  }
});
