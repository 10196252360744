import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let studiesSwiperWr = document.querySelectorAll('.studies-swiper-wr');
studiesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      centeredSlides: true,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        1024: {
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
    });
    swiperObserver(swiper);
  }
});

if(document.querySelector('.section-case-study__item-top')) {
  const studyTitlesArr = document.querySelectorAll('.section-case-study__item-top');
  setTimeout(function () {
    lineTitlesFunc(studyTitlesArr);
  }, 300)
}