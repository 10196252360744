import marquee from 'vanilla-marquee';

// Companies
document.addEventListener('DOMContentLoaded', () => {
  const marqueeElement = document.querySelector('#companies-marquee');
  
  if (marqueeElement) {
    const maxWidth = 768;

    const itemsArr = marqueeElement.querySelectorAll('.section-companies-company');

    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeElement.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeElement.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeElement.appendChild(clone);
    });

    function getMarqueeSpeed() {
      return window.innerWidth <= maxWidth ? 40 : 80;
    }

    const marqueeInstance = new marquee(marqueeElement, {
      pauseOnHover: true,
      duplicated: true,
      startVisible: true,
      direction: 'left',
      speed: getMarqueeSpeed(),
      gap: 16,
    });

    window.addEventListener('resize', () => {
      marqueeInstance.speed = getMarqueeSpeed();
    });
  } else {
    console.error('Element with ID "companies-marquee" not found');
  }
});