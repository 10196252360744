import './components/header.component';

import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/swiperOnResize.utils.js';
import './utils/parallax-on-view.utils.js';
import './utils/meta.utils.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/map.component.js';
import './components/validation.component.js';

import './libs/countUp.lib.js';

/* SWIPERS */
import './components/swiper-live-jobs.component';
import './components/swiper-latest-news.component';
import './components/swiper-latest-news-second.component';
import './components/swiper-offices.component';
import './components/swiper-footer-form.component';
import './components/swiper-story.component';
import './components/swiper-team.component';
import './components/swiper-testimonials.component';
import './components/swiper-candidates.component';
import './components/swiper-solutions.component';
import './components/swiper-work.component';
import './components/swiper-sectors.component.js';
import './components/swiper-studies.component.js';
import './components/swiper-stats.component.js';

/* MARQUEES */
import './components/marquee.component';

/* Enfore number pattern for forms */
document.querySelectorAll('input[type="tel"]').forEach((input) => {
  input.addEventListener('input', function () {
    this.value = this.value.replace(/[^0-9+]/g, '');
    if (this.value.length > 1 && this.value.indexOf('+') !== 0) {
      this.value = this.value.replace('+', '');
    }
  });
});

/* ACCORDIONS */

/* sectors accordion */
const sectorsAccordions = document.querySelectorAll('.sectors-accordion');
sectorsAccordions.forEach((accordion) => {
  accordion.addEventListener('click', () => {
    console.log('clicked accordion');
    if (!accordion.classList.contains('active')) {
      sectorsAccordions.forEach((otherAccordion) => {
        otherAccordion.classList.remove('active');
      });
      accordion.classList.add('active');
    }
  });
});

/* filters accordion */
const searchResultFilters = document.querySelectorAll('.search-filter-top');

function getFiltersState() {
  let devices = ['mobile', 'desktop'];

  return window.innerWidth <= 768 ? devices[0] : devices[1];
}

function addSearchResultFilters() {
  searchResultFilters.forEach((searchFilter, i) => {
    if (getFiltersState() === 'desktop') {
      searchFilter.parentElement.classList.add('active');
    } else {
      searchFilter.parentElement.classList.remove('active');

      if (i === 0) {
        searchFilter.parentElement.classList.add('active');
      }
    }

    searchFilter.addEventListener('click', function () {
      this.parentElement.classList.toggle('active');
    });
  });
}

addSearchResultFilters();

/* CONTENT ELEMENTS */
const contentElementLists = document.querySelectorAll(
  '.section-post-details-content .content-element ol'
);

contentElementLists.forEach((list) => {
  const listItems = list.querySelectorAll('li');

  listItems.forEach((item, index) => {
    const countContainer = document.createElement('div');
    const countText = document.createElement('span');
    countContainer.classList.add('list-count-container');
    countText.innerText = `${index + 1}`;
    countContainer.append(countText);
    item.prepend(countContainer);
  });
});

// Jobs search form

const jobsForm = document.querySelector('form#search_form');

if (jobsForm) {
  jobsForm.addEventListener('submit', (e) => {
    e.preventDefault();
    load('jobs/search', 'form:#search_form', 'form:#search_form2');
    return false;
  });
}
