import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let testimonialsSwiperWr = document.querySelectorAll('.testimonials-swiper-wr');
testimonialsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let paginationEl = el.querySelector('.swiper-pagination');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 25, 
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      pagination: {
        el: paginationEl,
        type: 'fraction',
      },
      breakpoints: {
        551: {
          slidesPerView: 1.4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 1.6,
          spaceBetween: 55,
        },
        1200: {
          slidesPerView: 1.79,
          spaceBetween: 86,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});
