import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let storySwiperWr = document.querySelectorAll('.story-swiper-wr');
storySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 15, 
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        551: {
          slidesPerView: 1,
          spaceBetween: 23,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 29,
        },
        1200: {
          slidesPerView: 1,
          spaceBetween: 35,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});
