import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let candidatesSwiperWr = document.querySelectorAll('.candidates-swiper-wr');
candidatesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 16,
      centeredSlides: false,
      initialSlide: 0,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        551: {
          initialSlide: 0,
          slidesPerView: 1.3,
          spaceBetween: 18,
          centeredSlides: false,
        },
        768: {
          slidesPerView: 1.4,
          centeredSlides: true,
          initialSlide: 1,
        },
        1024: {
          slidesPerView: 1.5,
          spaceBetween: 24,
          centeredSlides: true,
          initialSlide: 1,
        },
        1200: {
          slidesPerView: 1.71,
          spaceBetween: 30,
          centeredSlides: true,
          initialSlide: 1,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});
