import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';

let footerFormSwiperWr = document.querySelectorAll('.footer-form-swiper-wr');
footerFormSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let paginationEl = el.querySelector('.swiper-pagination');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Pagination, EffectFade],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 0,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      pagination: {
        el: paginationEl,
        type: 'fraction',
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      speed: 800,
      allowTouchMove: false,
      on: {
        init: function () {
          if (this.isBeginning) {
            prevEl.style.display = 'none';
          }
          checkInput(this);
          addInputEventListener(this);
          handleLastSlide(this);
          updateCustomPagination(this);
        },
        slideChange: function () {
          if (this.isBeginning) {
            prevEl.style.display = 'none';
          } else {
            prevEl.style.display = 'grid';
          }
          checkInput(this);
          addInputEventListener(this);
          handleLastSlide(this);
          updateCustomPagination(this);
        },
      },
    });

    function checkInput(swiper) {
      const currentSlide = swiper.slides[swiper.activeIndex];
      const input = currentSlide.querySelector('input:not(.not-required)');

      if (input) {
        const isEmailValid =
            input.type === 'email' &&
            input.value.includes('@') &&
            input.value.includes('.');

            // const isTelValid =
            //       input.type === 'tel' &&
            //       /^(\+7|8|44)?[\s\-]?(\(?\d{4}\)?[\s\-]?)?\d{3}[\s\-]?\d{2}[\s\-]?\d{2}$/.test(input.value); 

            const isTelValid =
                  input.type === 'tel' &&
                  input.value.replace(/\D/g, '').length >= 8 &&
                  input.value.replace(/\D/g, '').length <= 15;

        if ((input.type !== 'email' && input.type !== 'tel' && input.value.trim()) || isEmailValid || isTelValid) {
          nextEl.style.pointerEvents = 'auto';
          nextEl.style.opacity = '1';
          enableBullets(swiper);
          removeErrorText(input);
        } else {
          nextEl.style.pointerEvents = 'none';
          nextEl.style.opacity = '0.5';
          disableBullets(swiper);

          if (input.dataset.hasInput) {
            addErrorText(input, isEmailValid, isTelValid);
          } else {
            removeErrorText(input); // Удаляем ошибку, если пользователь ещё не ввел данные
          }
        }

        // Добавляем обработчик только при первом вводе
        if (!input.dataset.listenerAdded) {
          input.addEventListener('input', () => {
            input.dataset.hasInput = input.value.trim() !== ''; // Обновляем статус наличия ввода
            checkInput(swiper);
          });
          input.dataset.listenerAdded = true; // Помечаем, что обработчик добавлен
        }
      } else {
        nextEl.style.pointerEvents = 'none';
        nextEl.style.opacity = '0.5';
        disableBullets(swiper);
      }
    }

    function addErrorText(input, isEmailValid, isTelValid) {
      removeErrorText(input); // Удаляем старый текст ошибки перед добавлением нового
      const errorText = document.createElement('span');
      errorText.classList.add('error-text');
      errorText.classList.add('text-red-300');

      if (input.value.trim() === '') {
        errorText.textContent = 'The field must not be empty.';
      } else if (input.type === 'email' && !isEmailValid) {
        errorText.textContent = 'The email field must contain a valid email address.';
      } else if (input.type === 'tel' && !isTelValid) {
        errorText.textContent = 'The Phone field must be at least 8 characters in length.';
      }

      input.parentNode.appendChild(errorText);
      input.parentElement.classList.add('error');
      input.parentElement.parentElement.classList.add('error');
    }

    function removeErrorText(input) {
      const errorText = input.parentNode.querySelector('.error-text');
      if (errorText) {
        errorText.remove();
      }
      input.parentElement.classList.remove('error');
      input.parentElement.parentElement.classList.remove('error');
    }


    function addInputEventListener(swiper) {
      const currentSlide = swiper.slides[swiper.activeIndex];
      const input = currentSlide.querySelector('input');

      if (input) {
        input.addEventListener('input', () => {
          checkInput(swiper);
        });
      }
    }

    function handleLastSlide(swiper) {
      const isLastSlide = swiper.isEnd;
      const submitBtn = document.querySelector(
        ".footer-main-form__form button[type='submit']"
      );

      if (isLastSlide) {
        nextEl.style.display = 'none';
        prevEl.style.display = 'none';
        if (submitBtn) {
          submitBtn.style.display = 'grid';
          submitBtn.style.placeContent = 'center';
        }
      } else {
        nextEl.style.display = 'grid';
        prevEl.style.display = 'grid';
        if (submitBtn) {
          submitBtn.style.display = 'none';
        }
      }
    }

    function updateCustomPagination(swiper) {
      const bullets = el.querySelectorAll('.pagination-bullets div');
      bullets.forEach((bullet, index) => {
        if (index === swiper.activeIndex) {
          bullet.classList.add('active');
        } else {
          bullet.classList.remove('active');
        }
      });
    }

    function addCustomBulletListeners(swiper) {
      const bullets = el.querySelectorAll('.pagination-bullets div');
      bullets.forEach((bullet, index) => {
        bullet.addEventListener('click', () => {
          if (canNavigateTo(swiper, index)) {
            swiper.slideTo(index);
          }
        });
      });
    }

    function canNavigateTo(swiper, targetIndex) {
      for (let i = 0; i < targetIndex; i++) {
        const input = swiper.slides[i].querySelector('input');
        if (input && !input.value.trim()) {
          return false;
        }
      }
      return true;
    }

    function enableBullets(swiper) {
      const bullets = el.querySelectorAll('.pagination-bullets div');
      bullets.forEach((bullet, index) => {
        if (canNavigateTo(swiper, index)) {
          bullet.style.pointerEvents = 'auto';
          bullet.style.opacity = '1';
        }
      });
    }

    function disableBullets(swiper) {
      const bullets = el.querySelectorAll('.pagination-bullets div');
      bullets.forEach((bullet, index) => {
        if (index > swiper.activeIndex) {
          bullet.style.pointerEvents = 'none';
          bullet.style.opacity = '0.5';
        }
      });
    }

    addCustomBulletListeners(swiper);
    checkInput(swiper); 
  }
});