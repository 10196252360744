import { computePosition, offset } from '@floating-ui/dom';
import MetaMap from '../libs/metaMap.js';

function initMapFunc() {
  console.log('start init map');
  if (document.querySelector('#map')) {
    const mapEl = document.querySelector('#map');
    if (!mapEl.classList.contains('is-init')) {
      mapEl.classList.add('is-init');

      const countryList = ['GB', 'MX', 'CN'];
      const countryListDesk = ['GB', 'MX', 'CN', 'US'];

      const euCountries = [
        'GB',
        'BE',
        'PT',
        'PL',
        'NL',
        'BG',
        'IT',
        'DE',
        'FR',
        'ES',
        'HR',
        'RO',
        'SI',
        'SK',
        'CZ',
        'AT',
        'CH',
        'BA',
        'HU',
        'RS',
        'ME',
        'XK',
        'AL',
        'MK',
        'TR',
        'GR',
        'UK',
        'MD',
        'DK',
        'SE',
        'NO',
        'FI',
        'LT',
        'LV',
        'EE',
        'BY',
        'UA',
        'AZ',
        'GE',
        'AM',
        'IE',
      ];

      const naCountries = ['MX', 'US', 'CA'];

      const asiaCountries = ['SA', 'TM', 'IN', 'BD', 'TH', 'ID', 'CN', 'JP'];

      const mapSection = document.querySelector('.section-offices-map');
      const mapWrap = document.querySelector('#map');
      const popoversArr = mapSection.querySelectorAll('.map-popover');
      const locationsItemsArr = document.querySelectorAll(
        '.section-offices-top-left-continent'
      );
      const statsSlidersArr = document.querySelectorAll(
        '.section-offices__stats-container .section-offices__stats-slider'
      );

      let radius = 10;

      if (window.innerWidth <= 640) {
        radius = 10;
      }

      const isTab = matchMedia('(max-width: 768px)').matches;

      const config = {
        mapPath: 'app/assets/static/map.geo.json',
        width: 1344,
        height: 672,
        markers: [
          {
            long: 8.781537,
            lat: 44.754535, // IT
          },

          {
            long: -119.495901,
            lat: 56.022948, // CA
          },

          {
            long: 95.726457,
            lat: 31.090574, // CN
          },

          {
            long: -1.948121,
            lat: 50.771208, // GB
          },

          {
            long: -8.104481,
            lat: 53.206033, // IR
          },
        ],
        markerStyle: {
          img: 'app/assets/static/pin.svg',
          width: window.innerWidth >= 640 ? 13 : 13,
          height: window.innerWidth >= 640 ? 32 : 32,
        },
        selectedCountries: countryList,
        zoomedCountries:
          window.innerWidth >= 640 ? countryListDesk : countryList,
        accentFillColor: '#535B6E',
        accentStrokeColor: '#0A1530',
        countryFillColor: '#535B6E',
        countryStrokeColor: '#0A1530',
        countryStrokeWidth: '1px',
        // countryGroups: euCountries,
        maxZoom: 50,
        on: {
          markerClick: ({ data, target }) => {
            const markers = mapWrap.querySelectorAll('.marker');
            const activeMarkers = mapWrap.querySelectorAll('.marker.active');

            // Убираем класс active у всех маркеров
            activeMarkers.forEach((marker) =>
              marker.classList.remove('active')
            );

            // Убираем класс active у всех кнопок
            locationsItemsArr.forEach((item) =>
              item.classList.remove('active')
            );

            // Если клик по первому маркеру, активируем все кнопки и поповеры
            if (Array.from(markers).indexOf(target) === 0) {
              locationsItemsArr.forEach((item) => item.classList.add('active'));
              popoversArr.forEach((popover) => popover.classList.add('active'));
            } else {
              // Добавляем класс active к текущему маркеру
              target.classList.add('active');

              // Скрываем все поповеры
              popoversArr.forEach((popover) =>
                popover.classList.remove('active')
              );

              // Ищем индекс текущего маркера
              const index = Array.from(markers).indexOf(target);

              // Добавляем класс active соответствующей кнопке
              locationsItemsArr[index].classList.add('active');

              // Показываем соответствующий поповер
              const currentPopover = popoversArr[index];
              currentPopover.classList.add('active');

              // Обновляем позицию поповера
              updatePosition(target, currentPopover);
            }
          },
        },
      };

      try {
        const map = new MetaMap('#map', config);

        function initLocationsMap() {
          locationsItemsArr.forEach((location, i) => {
            location.addEventListener('click', () => {
              // Убираем класс active у всех кнопок выбора
              locationsItemsArr.forEach((item) =>
                item.classList.remove('active')
              );
              location.classList.add('active'); // Добавляем active для текущей кнопки

              const markers = mapWrap.querySelectorAll('.marker');
              const activeMarkers = mapWrap.querySelectorAll('.marker.active');

              // Убираем класс active у всех маркеров
              activeMarkers.forEach((marker) =>
                marker.classList.remove('active')
              );

              // Скрываем все поповеры
              popoversArr.forEach((popover) =>
                popover.classList.remove('active')
              );

              // Если клик по другим кнопкам, показываем только связанный маркер и поповер
              markers[i].classList.add('active');
              popoversArr[i].classList.add('active');

              // Обновляем позицию и заголовок поповера
              updatePosition(markers[i], popoversArr[i]);

              if (i === 0) {
                activeRegion(euCountries, '#1CCC7A');
              }

              if (i === 1) {
                activeRegion(naCountries, '#1D73D6');
              }

              if (i === 2) {
                activeRegion(asiaCountries, '#8E00FA');
              }
            });

            if (i === 1) {
              location.classList.add('active');
            }
          });

          const markers = mapWrap.querySelectorAll('.marker');
          markers.forEach((marker, index) => {
            marker.classList.add('active');
            popoversArr[index].classList.add('active');
            updatePosition(marker, popoversArr[index]);
            activeRegion(naCountries, '#1D73D6');

            setInterval(function () {
              updatePosition(marker, popoversArr[index]);
            }, 500);
            mapWrap.style.opacity = 1;
          });
        }

        setTimeout(function () {
          initLocationsMap();
        }, 500);

        setTimeout(function () {
          initLocationsMap();
        }, 2000);
      } catch (error) {
        console.error('Error initializing map:', error);
      }

      function updatePosition(pin, popover) {
        setTimeout(function () {
          computePosition(pin, popover, {
            placement: 'right',
            strategy: 'fixed',
            middleware: [offset(0)],
          }).then(({ x, y }) => {
            Object.assign(popover.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });
        }, 100);
      }

      function colorsGroup(list, color) {
        list.forEach((countryId) => {
          const path = document.querySelector(`path[id="${countryId}"]`);
          if (path) {
            path.style.fill = color;
          }
        });
      }

      setTimeout(function () {
        /*colorsGroup(euCountries, "#1CCC7A");*/
        colorsGroup(naCountries, '#1D73D6');
        /*colorsGroup(asiaCountries, "#8E00FA");*/
      }, 500);

      const euRegionPopovers = mapSection.querySelectorAll(
        '.map-popover.eu-region'
      );
      const naRegionPopovers = mapSection.querySelectorAll(
        '.map-popover.na-region'
      );
      const asiaRegionPopovers = mapSection.querySelectorAll(
        '.map-popover.asia-region'
      );

      euRegionPopovers.forEach((region) => {
        region.addEventListener('click', () => {
          activeRegion(euCountries, '#1CCC7A');
        });
      });

      naRegionPopovers.forEach((region) => {
        region.addEventListener('click', () => {
          activeRegion(naCountries, '#1D73D6');
        });
      });

      asiaRegionPopovers.forEach((region) => {
        region.addEventListener('click', () => {
          activeRegion(asiaCountries, '#8E00FA');
        });
      });

      function activeRegion(listCountries, color) {
        const markers = mapWrap.querySelectorAll('.marker');

        colorsGroup(euCountries, '#535B6E');
        colorsGroup(naCountries, '#535B6E');
        colorsGroup(asiaCountries, '#535B6E');
        colorsGroup(listCountries, color);

        // console.log(statsSlidersArr);
        // console.log(listCountries);
        // console.log(listCountries === euCountries);
        if (statsSlidersArr.length) {
          if (listCountries === naCountries) {
            markers[0].classList.add('eu-filter');
            markers[1].classList.remove('na-filter');
            markers[2].classList.add('asia-filter');
            markers[3].classList.add('eu-filter');
            markers[4].classList.add('eu-filter');

            for (let j = 0; j < statsSlidersArr.length; j++) {
              statsSlidersArr[j].classList.remove('active');
            }
            statsSlidersArr[0].classList.add('active');
          } else if (listCountries === euCountries) {
            markers[0].classList.remove('eu-filter');
            markers[1].classList.add('na-filter');
            markers[2].classList.add('asia-filter');
            markers[3].classList.remove('eu-filter');
            markers[4].classList.remove('eu-filter');

            for (let j = 0; j < statsSlidersArr.length; j++) {
              statsSlidersArr[j].classList.remove('active');
            }
            statsSlidersArr[1].classList.add('active');
          } else if (listCountries === asiaCountries) {
            markers[0].classList.add('eu-filter');
            markers[1].classList.add('na-filter');
            markers[2].classList.remove('asia-filter');
            markers[3].classList.add('eu-filter');
            markers[4].classList.add('eu-filter');

            for (let j = 0; j < statsSlidersArr.length; j++) {
              statsSlidersArr[j].classList.remove('active');
            }
            statsSlidersArr[2].classList.add('active');
          }
        }
      }
    }
  }
}

createVisibilityObserver('section-offices', initMapFunc, 0.01);

setTimeout(function () {
  Promise.all([
    document.fonts.ready,
    new Promise((resolve) => {
      if (document.readyState === 'complete') {
        resolve();
      } else {
        window.addEventListener('load', resolve);
      }
    }),
  ]).then(initMapFunc);
}, 1000);
