import tailwind from '~/tailwind.config.cjs';

export function initAnimationOnView(options = {}) {
  const defaultOptions = {
    animateOnce: true,
    speed: 600,
    threshold: 0.4, // Default threshold
    delay: 0,
    easing: 'ease',
    disableMutationObserver: false,
    breakpoints: {},
  };

  // Export breakpoints from tailwind
  if (Object.keys(tailwind.theme.screens).length) {
    defaultOptions.breakpoints = tailwind.theme.screens;
  }

  const { breakpoints } = defaultOptions;
  const mergedBreakpoints = Object.assign(breakpoints, options.breakpoints);
  const mainOptions = Object.assign(defaultOptions, options);

  mainOptions.breakpoints = mergedBreakpoints;

  /* --- Set global properties --- */
  function setGlobalProperty(name, value, isTimeUnit) {
    if (isTimeUnit) {
      value += 'ms';
    }
    document.documentElement.style.setProperty(`--view-${name}`, `${value}`);
  }

  const { speed, easing } = mainOptions;

  setGlobalProperty('speed', speed, true);
  setGlobalProperty('easing', easing);

  // Function to get the appropriate threshold based on screen size
  function getThreshold(element) {
    const dataset = element.dataset;

    // Check for mobile threshold and desktop threshold
    const desktopThreshold = dataset.viewThreshold
      ? parseFloat(dataset.viewThreshold)
      : mainOptions.threshold;
    const mobileThreshold = dataset.viewThresholdMobile
      ? parseFloat(dataset.viewThresholdMobile)
      : desktopThreshold;

    // If screen width is less than 768px, return mobile threshold, else desktop threshold
    return window.innerWidth < 768 ? mobileThreshold : desktopThreshold;
  }

  // Function to observe element with individual threshold
  function observeElement(element) {
    const threshold = getThreshold(element);

    // Create a new IntersectionObserver with the calculated threshold
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Show element when it meets the threshold
          if (entry.isIntersecting && entry.intersectionRatio >= threshold) {
            entry.target.classList.add('animated');
          }
          // Hide element if not intersecting and animateOnce is false
          else if (!entry.isIntersecting && !mainOptions.animateOnce) {
            entry.target.classList.remove('animated');
          }
        });
      },
      { threshold: threshold }
    );

    // Start observing the element
    observer.observe(element);
  }

  // Function to handle elements with disable-on-mobile option
  function intersectElement(element, isMutation = false) {
    element.classList.add('in-view');

    const dataset = element.dataset;

    // Check for mobile disable and handle it
    if (dataset.viewDisableMobile === 'true' && window.innerWidth < 768) {
      element.classList.add('animated'); // Activate immediately on mobile
      return; // Stop further processing for this element
    }

    // Set speed and easing to current element
    if (dataset.viewSpeed) {
      element.style.transitionDuration = `${dataset.viewSpeed}ms`;
    }

    if (dataset.viewEasing) {
      element.style.transitionTimingFunction = dataset.viewEasing;
    }

    // Delay observer if viewDelay is specified
    if (dataset.viewDelay) {
      setTimeout(() => observeElement(element), dataset.viewDelay);
    } else {
      observeElement(element);
    }
  }

  // Find all elements with data-view attribute and observe them
  const targetElements = document.querySelectorAll('[data-view]');

  if (targetElements.length) {
    targetElements.forEach((target) => intersectElement(target));
  }

  /* --- Intersect dynamically added elements in DOM --- */
  if (!mainOptions.disableMutationObserver) {
    // Callback function to handle the mutations
    const handleMutations = function (mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes.length) {
          // Check if any added node has the desired dataset
          const addedNodes = mutation.addedNodes;

          addedNodes.forEach((target) => {
            if (target.dataset && 'view' in target.dataset) {
              intersectElement(target, true);
            }
          });
        }
      }
    };

    // Create a MutationObserver instance
    const mutationObserver = new MutationObserver(handleMutations);
    const mutationObserverOptions = {
      childList: true,
      subtree: true,
      characterData: false,
    };

    mutationObserver.observe(document.body, mutationObserverOptions);
  }
}

// Test call
initAnimationOnView();
