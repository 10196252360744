import Swiper, { Navigation, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const circleSizes = [175, 96, 72, 48];
const imageSizes = [175, 96, 72, 48];

function createCircularNavigation(totalSlides, activeIndex, container) {
  const svgSize = 750; // Размер SVG
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', '100%');
  svg.setAttribute('height', '100%');
  svg.setAttribute('viewBox', `0 0 ${svgSize} ${svgSize}`);

  const centerX = svgSize / 2; // Центр круга по X
  const centerY = svgSize / 2; // Центр круга по Y
  const radius = svgSize / 2 - 100; // Радиус круга

  // Основной круг
  const mainCircle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle'
  );
  mainCircle.setAttribute('cx', centerX);
  mainCircle.setAttribute('cy', centerY);
  mainCircle.setAttribute('r', radius);
  mainCircle.setAttribute('fill', '#0A153080');
  mainCircle.setAttribute('stroke', '#3ac0fa');
  mainCircle.setAttribute('stroke-width', '1');
  mainCircle.classList.add('main-circle');
  svg.appendChild(mainCircle);

  const hiddenImages = document
      .querySelector('.hidden-images')
      .querySelectorAll('img');

  const circleGroup = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
  );
  circleGroup.classList.add('circle-group');
  svg.appendChild(circleGroup);

  // Расчет размещения кружков
  const leftX = centerX - radius; // Левый край круга
  const stepY = (2 * radius) / (totalSlides - 1); // Шаг между кружками по Y

  for (let i = 0; i < totalSlides; i++) {
    // Координаты кружка
    const x = leftX; // Все кружки на левом краю
    const y = centerY - radius + i * stepY; // Равномерное распределение по вертикали

    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.dataset.index = i.toString();

    const circle = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
    );
    circle.setAttribute('cx', x.toString());
    circle.setAttribute('cy', y.toString());

    let distance = Math.min(
        Math.abs(i - activeIndex),
        Math.abs(i - activeIndex - totalSlides),
        Math.abs(i - activeIndex + totalSlides)
    );
    let circleSize = getSize(distance, circleSizes);

    circle.setAttribute('r', circleSize.toString());
    circle.setAttribute('fill', i === activeIndex ? '#3ac0fa' : '#0a1530');
    circle.style.cursor = 'pointer';
    circle.style.borderRadius = '50%';
    circle.style.overflow = 'hidden';
    circle.setAttribute('stroke', '#3ac0fa');
    circle.setAttribute('stroke-width', '1');
    circle.style.transition = 'all 0.3s ease';

    group.appendChild(circle);

    if (i < hiddenImages.length) {
      const image = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'image'
      );
      image.setAttributeNS(
          'http://www.w3.org/1999/xlink',
          'href',
          hiddenImages[i].src
      );

      updateImageSizeAndPosition(image, x, y, distance);

      image.style.cursor = 'pointer';
      image.style.transition = 'all 0.3s ease';
      group.appendChild(image);
    }

    circleGroup.appendChild(group);
  }

  container.innerHTML = '';
  container.appendChild(svg);

  return svg;
}

function updateImageSizeAndPosition(image, cx, cy, distance) {
  const imageSize = getSize(distance, imageSizes);

  image.setAttribute('width', (imageSize * 2).toString());
  image.setAttribute('height', (imageSize * 2).toString());
  image.setAttribute('x', (cx - imageSize).toString());
  image.setAttribute('y', (cy - imageSize).toString());

  // Ensure the image stays upright
  image.setAttribute('transform', `rotate(0, ${cx}, ${cy})`);
}

function getSize(distance, sizeArray) {
  return distance < sizeArray.length
    ? sizeArray[distance] / 2
    : sizeArray[sizeArray.length - 1] / 2;
}

let workSwiperWr = document.querySelectorAll('.work-swiper-wr');
workSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    const navigationContainer = document.createElement('div');
    navigationContainer.className = 'circular-navigation';
    el.appendChild(navigationContainer);

    let svg;
    let currentRotation = 0;

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, EffectFade],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 0,
      rewind: false,
      loop: false,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
      on: {
        init: function (swiper) {
          svg = createCircularNavigation(
            swiper.slides.length,
            swiper.activeIndex,
            navigationContainer
          );

          rotateNavigation(
            swiper.realIndex,
            swiper.realIndex,
            swiper.slides.length
          );
        },
        slideChange: function (swiper) {
          console.log(`Slide changed to index: ${swiper.realIndex}`);
          rotateNavigation(
            swiper.realIndex,
            swiper.previousIndex,
            swiper.slides.length
          );
        },
      },
    });

    function rotateNavigation(newIndex, oldIndex, totalSlides) {
      if (!svg) return;

      const anglePerSlide = (2 * Math.PI) / totalSlides;
      let rotationAngle;

      const hiddenImages = document
          .querySelector('.section-work .hidden-images')
          .querySelectorAll('img');
      const hiddenActiveImage = document.querySelectorAll('.section-work .hidden-active-images img');

      let clockwiseDistance = (newIndex - oldIndex + totalSlides) % totalSlides;
      let counterclockwiseDistance =
        (oldIndex - newIndex + totalSlides) % totalSlides;

      if (clockwiseDistance <= counterclockwiseDistance) {
        rotationAngle = clockwiseDistance * anglePerSlide;
      } else {
        rotationAngle = -counterclockwiseDistance * anglePerSlide;
      }

      currentRotation += rotationAngle;

      const svgSize = 750;
      const centerX = svgSize / 2;
      const centerY = svgSize / 2;
      const radius = svgSize / 2 - 100;

      const groups = svg.querySelectorAll('g[data-index]');

      groups.forEach((group) => {
        const circle = group.querySelector('circle');
        const image = group.querySelector('image');
        const groupIndex = parseInt(group.dataset.index, 10);

        const angle =
          (groupIndex / totalSlides) * 2 * Math.PI - Math.PI - currentRotation;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        circle.setAttribute('cx', x.toString());
        circle.setAttribute('cy', y.toString());

        let distance = Math.min(
          Math.abs(groupIndex - newIndex),
          Math.abs(groupIndex - newIndex - totalSlides),
          Math.abs(groupIndex - newIndex + totalSlides)
        );

        let circleSize = getSize(distance, circleSizes);

        if (groupIndex === newIndex) {
          circle.setAttribute('fill', '#3ac0fa');
          if (image) {
            /*image.style.filter = 'brightness(0) invert(1)';*/
            image.setAttributeNS(
                'http://www.w3.org/1999/xlink',
                'href',
                hiddenActiveImage[groupIndex].src
            );
          }
        } else {
          circle.setAttribute('fill', '#0a1530');
          if (image) {
            image.setAttributeNS(
                'http://www.w3.org/1999/xlink',
                'href',
                hiddenImages[groupIndex].src
            );
          }
        }

        circle.setAttribute('r', circleSize.toString());
        circle.setAttribute('stroke', '#3ac0fa');
        circle.setAttribute('stroke-width', '1');

        if (image) {
          updateImageSizeAndPosition(image, x, y, distance);
        }
      });
    }

    let isTransitioning = false; // Стан затримки

    navigationContainer.addEventListener('click', (event) => {
      if (isTransitioning) return; // Запобігти повторним клікам під час затримки

      const clickedGroup = event.target.closest('g');
      if (clickedGroup) {
        const index = parseInt(clickedGroup.dataset.index, 10);

        // Додаємо клас "deactive" всім групам
        const groups = navigationContainer.querySelectorAll('g[data-index]');
        groups.forEach((group) => {
          group.classList.add('deactive');
        });

        isTransitioning = true; // Встановлюємо стан затримки

        // Видаляємо клас "deactive" через 300 мс
        setTimeout(() => {
          groups.forEach((group) => {
            group.classList.remove('deactive');
          });
          isTransitioning = false; // Дозволяємо взаємодію
        }, 300);

        // Переходимо до відповідного слайду
        swiper.slideTo(index);
      }
    });

    // navigationContainer.addEventListener('click', (event) => {
    //   const clickedGroup = event.target.closest('g');
    //   if (clickedGroup) {
    //     const index = parseInt(clickedGroup.dataset.index, 10);
    //     swiper.slideTo(index);
    //   }
    // });

    let wheelDelta = 0;
    const wheelThreshold = 100;
    let lastWheelTime = 0;
    const wheelDelay = 250;

    /*navigationContainer.addEventListener('wheel', (event) => {
      event.preventDefault();

      const currentTime = new Date().getTime();

      if (currentTime - lastWheelTime < wheelDelay) {
        return;
      }

      wheelDelta += event.deltaY;

      if (Math.abs(wheelDelta) >= wheelThreshold) {
        if (wheelDelta > 0) {
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
        wheelDelta = 0;
        lastWheelTime = currentTime;
      }
    });

    navigationContainer.addEventListener('mouseleave', () => {
      wheelDelta = 0;
    });*/
  }
});

/*CURRENT CARD COUNT*/
const wordCards = document.querySelectorAll('.work-card');
wordCards.forEach((card, i) => {
  const title = card.querySelector('.work-card-top__title');
  const countContainer = document.createElement('div');
  const count = document.createElement('span');
  count.innerText = i + 1;
  countContainer.classList.add('work-card-top__count');
  countContainer.append(count);
  title.parentElement.prepend(countContainer);
});
