import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let solutionsSwiperWr = document.querySelectorAll('.solutions-swiper-wr');
solutionsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 16,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        551: {
          slidesPerView: 1.2,
          spaceBetween: 18,
        },
        768: {
          slidesPerView: 1.3,
        },
        1024: {
          slidesPerView: 1.4,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 1.495,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});

const solutionAccordionWr = document.querySelectorAll('.section-hiring-solutions-hero__accordion');
solutionAccordionWr.forEach(accordion => {
  if(accordion) {
    const items = accordion.querySelectorAll('.section-hiring-solutions-hero__accordion-item');
    items.forEach((item, i) => {
      item.addEventListener('click', () => {
        for (let j = 0; j < items.length; j++) {
          items[j].classList.remove('active');
        }
        item.classList.add('active');
      })
    })
  }
})
