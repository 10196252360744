import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let newsSecondSwiperWr = document.querySelectorAll('.latest-news-second-swiper-wr');
newsSecondSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 32, 
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        551: {
          slidesPerView: 2.1,
          spaceBetween: 36,
        },
        1024: {
          slidesPerView: 2.6,
          spaceBetween: 48,
        },
        1200: {
          slidesPerView: 3.06,
          spaceBetween: 60,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});
