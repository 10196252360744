import Swiper, { Autoplay, Navigation, Pagination, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let officesWr = document.querySelectorAll('.offices-swiper-wr');
officesWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let paginationEl = el.querySelector('.swiper-pagination');

    swiperOnResize('(max-width: 768px)', swiperEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 2.1,
      spaceBetween: 16,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        400: {
          slidesPerView: 2.3,
          spaceBetween: 28,
        },
        551: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      pagination: {
        el: paginationEl,
        type: 'progressbar',
      },
    });
  }
});
