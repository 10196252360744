// v1.1.0

import {
  select,
  geoPath,
  geoMercator,
  zoom,
  json,
  selectAll,
  zoomIdentity,
} from 'd3';

const DEFAULT_MARKERS_STYLE = {
  color: 'blue',
  img: null,
  width: 20,
  height: 20,
  radius: 2,
};

const DEFAULT_ON = {
  countryClick: () => {},
  countryMouseEnter: () => {},
  countryMouseLeave: () => {},
  markerClick: () => {},
  markerMouseEnter: () => {},
  markerMouseLeave: () => {},
};

/**
 * @class MetaMap
 * @description
 * This class is used to create a map of countries with a set of markers and a set of countries.
 *
 * @param {string} selector - The selector of the element to create the map.
 * @param {object} options - The options of the map.
 */
export default class MetaMap {
  constructor(selector, config) {
    this.selector = selector;
    this.config = config;
    const {
      maxZoom,
      zoomedCountries,

      countryGroups,

      selectedCountries,

      countryStrokeWidth,
      countryFillColor,
      countryStrokeColor,
      accentFillColor,
      accentStrokeColor,
      groupFillColor,

      width,
      height,

      markers,
      markerStyle,

      on,
    } = this.config;

    this.maxZoom = maxZoom ?? 20;
    this.zoomedCountries = zoomedCountries;
    this.countryGroups = countryGroups
      ? new Map(
          countryGroups.map((obj) => {
            return [obj.id, obj.countryList];
          })
        )
      : null;

    this.countryStrokeWidth = countryStrokeWidth ?? '0.25px';
    this.countryFillColorType =
      typeof this.config.countryFillColor === 'string' ? 'string' : 'gradient';
    this.countryFillColor =
      this.countryFillColorType === 'string'
        ? this.config.countryFillColor
        : 'url(#countryGradient)' ?? '#cccccc';
    this.countryGradientSettings =
      this.countryFillColor === 'string' ? null : countryFillColor;
    this.countryStrokeColor = countryStrokeColor ?? '#ffffff';

    this.accentFillColorType =
      typeof accentFillColor === 'string' ? 'string' : 'gradient';
    this.accentFillColor =
      this.accentFillColorType === 'string'
        ? accentFillColor
        : 'url(#accentGradient)' ?? 'red';
    this.groupFillColorType =
      typeof groupFillColor === 'string' ? 'string' : 'gradient';
    this.groupFillColor =
      this.groupFillColorType === 'string'
        ? groupFillColor
        : 'url(#groupGradient)' ?? 'yellow';
    this.accentGradientSettings =
      this.accentFillColorType === 'string' ? null : accentFillColor;
    this.accentStrokeColor = accentStrokeColor ?? '#ffffff';

    this.width = width ?? 900;
    this.height = height ?? 600;

    this.selectedCountries = selectedCountries ?? [];

    this.markers = markers ?? [];

    this.markerStyle = {
      ...DEFAULT_MARKERS_STYLE,
      ...markerStyle,
    };

    this.on = { ...DEFAULT_ON, ...on };

    this.init();
  }
  init() {
    this.mapInstancePromise = json(this.config.mapPath);

    this.#setupSvg();
    this.#setupMap();
    this.#setupGradients();
    this.#setupZoom();
    this.#mapRender();
    this.selectCountryList(this.selectedCountries);

    if (this.zoomedCountries) this.zoomCountries(this.zoomedCountries);

    this.#setupCountryListeners();

    // Markers init
    setTimeout(() => {
      this.#markersRender();
      this.#setupMarkerListeners();
      this.#setupCountryGroups();
    });
  }

  #setupSvg() {
    this.svg = select(this.selector)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .style('fill', this.countryFillColor)
      .style('stroke', '#fff')
      .style('stroke-width', this.countryStrokeWidth);
  }

  #setupMap() {
    this.scale = this.width / (Math.PI * 2);
    this.translate = [this.width / 2, this.height / 1.4];
    this.projection = geoMercator()
      .fitSize([500, 500])
      .scale(this.scale)
      .translate(this.translate);
    this.path = geoPath(this.projection);
    this.g = this.svg.append('g');
    this.g.attr('stroke', 'transparent');
  }

  #setupGradients() {
    this.defs = this.svg.append('defs');

    if (this.accentFillColorType === 'gradient') {
      this.accentGradient = this.defs
        .append('linearGradient')
        .attr('id', 'accentGradient')
        .attr(
          'gradientTransform',
          `rotate(${this.accentGradientSettings.rotate})`
        );

      this.accentGradient
        .append('stop')
        .attr('offset', this.accentGradientSettings?.startColor?.offset ?? '0%')
        .attr(
          'stop-color',
          this.accentGradientSettings?.startColor?.color ??
            this.accentGradientSettings?.startColor ??
            '#ffffff'
        );
      this.accentGradient
        .append('stop')
        .attr('offset', this.accentGradientSettings?.endColor?.offset ?? '100%')
        .attr(
          'stop-color',
          this.accentGradientSettings?.endColor?.color ??
            this.accentGradientSettings?.endColor ??
            '#000000'
        );
    }
    if (this.countryFillColorType === 'gradient') {
      this.countryGradient = this.defs
        .append('linearGradient')
        .attr('id', 'countryGradient')
        .attr(
          'gradientTransform',
          `rotate(${this.countryGradientSettings.rotate})`
        );

      this.countryGradient
        .append('stop')
        .attr(
          'offset',
          this.countryGradientSettings?.startColor?.offset ?? '0%'
        )
        .attr(
          'stop-color',
          this.countryGradientSettings?.startColor?.color ??
            this.countryGradientSettings?.startColor ??
            '#ffffff'
        );
      this.countryGradient
        .append('stop')
        .attr(
          'offset',
          this.countryGradientSettings?.endColor?.offset ?? '100%'
        )
        .attr(
          'stop-color',
          this.countryGradientSettings?.endColor?.color ??
            this.countryGradientSettings?.endColor ??
            '#000000'
        );
    }
  }

  #setupZoom() {
    this.zoom = zoom()
      .scaleExtent([1, this.maxZoom])
      .translateExtent([
        [0, 0],
        [this.width, this.height],
      ])
      .on('zoom', (e) => zoomed(e, this));

    function zoomed(e, metaMap) {
      metaMap.g.attr('transform', e.transform);
    }

    if (this.config.isZoomable) {
      this.svg.call(this.zoom);
    }
  }

  #mapRender() {
    this.mapInstancePromise.then((data) => {
      this.g
        .selectAll('path')
        .data(data.features)
        .enter()
        .append('path')
        .attr('class', 'country')
        .attr('id', (d) => d.properties.id)
        .attr('d', this.path);

      if (this.g.node().childNodes[0] === this.g.select('.markers').node()) {
        this.g.append('use').attr('xlink:href', '#markers');
      }
    });
  }

  #setupCountryGroups() {
    if (!this.countryGroups) return;

    this.mapInstancePromise.then(() => {
      this.countryGroups.forEach((countryList, key) => {
        const groupedCountriesSelector = countryList
          .map((item) => `#${item}`)
          .join(',');

        selectAll(groupedCountriesSelector)
          .attr('class', () => `country ${key}`)
          .transition()
          .style('fill', this.groupFillColor)
          .style('stroke', this.countryStrokeColor);
      });
    });
  }

  #markersRender() {
    this.mapInstancePromise.then(() => {
      const markerGroup = this.g
        .append('g')
        .attr('class', 'markers')
        .attr('id', 'markers');
      const markers = markerGroup
        .selectAll('.marker')
        .data(this.markers)
        .enter();
      if (this.markerStyle.img) {
        markers
          .append('svg:image')
          .attr(
            'x',
            (d) =>
              this.projection([d.long, d.lat])[0] - this.markerStyle.width / 2
          )
          .attr('class', 'marker')
          .attr(
            'y',
            (d) =>
              this.projection([d.long, d.lat])[1] - this.markerStyle.height / 2
          )
          .attr('width', this.markerStyle.width)
          .attr('height', this.markerStyle.height)
          .attr('xlink:href', this.markerStyle.img);
      } else {
        markers
          .append('circle')
          .attr('class', 'marker')
          .attr('r', this.markerStyle.radius)
          .attr('cx', (d) => this.projection([d.long, d.lat])[0])
          .attr('cy', (d) => this.projection([d.long, d.lat])[1])
          .style('fill', this.markerStyle.color);
      }
    });
  }

  #setupCountryListeners() {
    this.mapInstancePromise.then(() => {
      selectAll(`.country`)
        .on('click', ({ target }, data) =>
          this.on.countryClick({
            target,
            data,
            metaMap: this,
          })
        )
        .on('mouseenter', ({ target }, data) =>
          this.on.countryMouseEnter({ target, data, metaMap: this })
        )
        .on('mouseleave', ({ target }, data) =>
          this.on.countryMouseLeave({ target, data, metaMap: this })
        );
    });
  }

  #setupMarkerListeners() {
    this.mapInstancePromise.then(() => {
      selectAll(`.marker`)
        .on('click', ({ target }, data) =>
          this.on.markerClick({ target, data, metaMap: this })
        )
        .on('mouseenter', ({ target }, data) =>
          this.on.markerMouseEnter({ target, data, metaMap: this })
        )
        .on('mouseleave', ({ target }, data) =>
          this.on.markerMouseLeave({ target, data, metaMap: this })
        );
    });
  }

  /**
   * @description Select country by id
   * @param {string} id - country id
   */
  selectCountry(id) {
    if (!id) throw new Error('id is required!');

    this.selectedCountries = [...this.selectedCountries, id];

    this.mapInstancePromise.then(() => {
      select(`#${id}`)
        .transition()
        .style('fill', this.accentFillColor)
        .style('stroke', this.accentStrokeColor);
    });
  }

  /**
   * @description Select country group by  group id
   * @param {string} groupId - group id
   */
  selectGroup(groupId) {
    if (!groupId) throw new Error('groupId is required!');

    this.selectedGroup = groupId;

    const groupedCountriesSelector = this.countryGroups
      .get(this.selectedGroup)
      .map((item) => `#${item}`)
      .join(',');

    this.mapInstancePromise.then(() => {
      selectAll(groupedCountriesSelector)
        .transition()
        .style('fill', this.accentFillColor)
        .style('stroke', this.accentStrokeColor);
    });
  }

  /**
   * @description Select country group by  group id
   * @param {string} groupId - group id
   */
  unselectAllGroups() {
    const groupedCountriesSelector = this.countryGroups
      .get(this.selectedGroup)
      .map((item) => `#${item}`)
      .join(',');

    this.mapInstancePromise.then(() => {
      selectAll(groupedCountriesSelector)
        .transition()
        .style('fill', this.groupFillColor)
        .style('stroke', this.countryStrokeColor);
    });

    this.selectedGroup = null;
  }

  /**
   * @description Select countries by id.
   * @param {string[]} idList - List of country ids.
   */
  selectCountryList(idList) {
    if (!idList) throw new Error('idList is required!');

    this.mapInstancePromise.then(() => {
      idList?.forEach((id) => {
        this.selectCountry(id);
      });
    });
  }

  /**
   * @description Unselect country by id.
   * @param {string} id - List of country ids.
   */
  unselectCountry(id) {
    if (!id) throw new Error('id is required!');

    select(`#${id}`)
      .transition()
      .style('fill', this.countryFillColor)
      .style('stroke', this.countryStrokeColor);
  }

  /**
   * @description Unselect all countries.
   */
  unselectAllCountries() {
    const selector = this.selectedCountries.map((item) => `#${item}`).join(',');

    this.mapInstancePromise.then(() => {
      selectAll(selector)
        .transition()
        .style('fill', this.countryFillColor)
        .style('stroke', this.countryStrokeColor);
    });
  }

  moveToCountry(id, metaMap = this) {
    if (!id) throw new Error('id is required!');

    this.mapInstancePromise.then(() => {
      const [d] = select(`#${id}`).data();
      const bounds = metaMap.path.bounds(d);
      const dx = bounds[1][0] - bounds[0][0];
      const dy = bounds[1][1] - bounds[0][1];
      const x = (bounds[0][0] + bounds[1][0]) / 2;
      const y = (bounds[0][1] + bounds[1][1]) / 2;
      const scale = Math.max(
        1,
        Math.min(
          this.maxZoom,
          0.9 / Math.max(dx / metaMap.width, dy / metaMap.height)
        )
      );
      const translate = [
        metaMap.width / 2 - scale * x,
        metaMap.height / 2 - scale * y,
      ];
      metaMap.svg
        .transition()
        .duration(750)
        .call(
          metaMap.zoom.transform,
          zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        );
    });
  }

  moveToCountries(idList, metaMap = this) {
    if (!idList || !Array.isArray(idList))
      throw new Error(
        'idList is required and must be an array of country ids!'
      );

    this.mapInstancePromise.then(() => {
      const [firstD] = select(`#${idList[0]}`).data();
      const resBounds = metaMap.path.bounds(firstD);
      idList.forEach((id) => {
        const [d] = select(`#${id}`).data();
        const bounds = metaMap.path.bounds(d);
        if (resBounds[0][0] > bounds[0][0]) resBounds[0][0] = bounds[0][0];
        if (resBounds[0][1] > bounds[0][1]) resBounds[0][1] = bounds[0][1];
        if (resBounds[1][0] < bounds[1][0]) resBounds[1][0] = bounds[1][0];
        if (resBounds[1][1] < bounds[1][1]) resBounds[1][1] = bounds[1][1];
      });
      const dx = resBounds[1][0] - resBounds[0][0];
      const dy = resBounds[1][1] - resBounds[0][1];
      const x = (resBounds[0][0] + resBounds[1][0]) / 2;
      const y = (resBounds[0][1] + resBounds[1][1]) / 2;
      const scale = Math.max(
        1,
        Math.min(
          this.maxZoom,
          0.9 / Math.max(dx / metaMap.width, dy / metaMap.height)
        )
      );
      const translate = [
        metaMap.width / 2 - scale * x,
        metaMap.height / 2 - scale * y,
      ];
      metaMap.svg
        .transition()
        .duration(750)
        .call(
          metaMap.zoom.transform,
          zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        );
    });
  }

  zoomCountries(idList) {
    if (!idList)
      throw new Error('id "string" or idList "array of strings" is required!');

    this.mapInstancePromise.then(() => {
      if (typeof idList === 'string') {
        const [d] = select(`#${idList}`).data();
        const bounds = this.path.bounds(d);
        const dx = bounds[1][0] - bounds[0][0];
        const dy = bounds[1][1] - bounds[0][1];
        const x = (bounds[0][0] + bounds[1][0]) / 2;
        const y = (bounds[0][1] + bounds[1][1]) / 2;
        const scale = Math.max(
          1,
          Math.min(
            this.maxZoom,
            0.9 / Math.max(dx / this.width, dy / this.height)
          )
        );
        const translate = [
          this.width / 2 - scale * x,
          this.height / 2 - scale * y,
        ];
        this.svg.call(
          this.zoom.transform,
          zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        );
      }
      if (Array.isArray(idList)) {
        const [firstD] = select(`#${idList[0]}`).data();
        const resBounds = this.path.bounds(firstD);
        idList.forEach((id) => {
          const [d] = select(`#${id}`).data();
          const bounds = this.path.bounds(d);
          if (resBounds[0][0] > bounds[0][0]) resBounds[0][0] = bounds[0][0];
          if (resBounds[0][1] > bounds[0][1]) resBounds[0][1] = bounds[0][1];
          if (resBounds[1][0] < bounds[1][0]) resBounds[1][0] = bounds[1][0];
          if (resBounds[1][1] < bounds[1][1]) resBounds[1][1] = bounds[1][1];
        });
        const dx = resBounds[1][0] - resBounds[0][0];
        const dy = resBounds[1][1] - resBounds[0][1];
        const x = (resBounds[0][0] + resBounds[1][0]) / 2;
        const y = (resBounds[0][1] + resBounds[1][1]) / 2;
        const scale = Math.max(
          1,
          Math.min(
            this.maxZoom,
            0.9 / Math.max(dx / this.width, dy / this.height)
          )
        );
        const translate = [
          this.width / 2 - scale * x,
          this.height / 2 - scale * y,
        ];
        this.svg.call(
          this.zoom.transform,
          zoomIdentity.translate(...translate).scale(scale)
        );
      }
    });
  }
}
